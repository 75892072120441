// React-related imports
import React from "react";
import { Route } from "react-router-dom";

// React Admin
import { Admin, CustomRoutes, Resource } from "react-admin";

// Higher Order Components (HOC)
import { withAuth } from "@bytenite/auth/src/hoc/Auth/Provider";
import ApiProvider from "../hoc/ApiProvider";
import AuthProvider from "../hoc/AuthProvider";

// Auth Context
import { AuthContextInterface, User } from "@bytenite/auth/src/hoc/Auth/context";

// Pages
import Callback from "../pages/Callback";
import LoginPage from "../pages/Login";
import Logout from "../pages/Logout";

// Material UI Icons
import {
  Apps as AppsIcon,
  AutoStories,
  Cloud,
  DataObject,
  LocalOffer,
  Person,
  PhoneAndroid,
  Source,
  Work
} from "@mui/icons-material";

import KeyIcon from '@mui/icons-material/Key';

// Pages - Components
import { AccessCodeCreate, AccessCodeEdit, AccessCodeList, AccessCodeShow } from "../pages/AccessCode";
import { AppList, AppShow } from "../pages/Apps";
import { CloudRunnerShow, CloudRunnersList } from "../pages/CloudRunners";
import { CouponCreate, CouponList, CouponShow } from "../pages/Coupons";
import { Dashboard } from "../pages/Dashboard";
import { DeviceShow, DevicesList } from "../pages/Devices";
import { JobPresetCreate, JobPresetEdit, JobPresetList, JobPresetShow } from "../pages/JobPresets";
import { JobCreate, JobEdit, JobShow, JobsList } from "../pages/Jobs";
import { JobTemplateList, JobTemplateShow } from "../pages/JobTemplates";
import { SchemaList, SchemaShow } from "../pages/Schemas";
import { TaskShow } from "../pages/Tasks";
import { UserEdit, UserList, UserShow } from "../pages/Users";


export interface AdminProps {
  loginPage?: any;
  auth: AuthContextInterface<User>
  serverUrl: string
}

//const dataProvider = jsonServerProvider("https://jsonplaceholder.typicode.com");


const App = ({ loginPage, auth, serverUrl, ...props }: AdminProps) => {
  const authProvider = AuthProvider(auth)
  const dataProvider = ApiProvider(`${serverUrl}/v1/admin`, auth)

  return <Admin dataProvider={dataProvider} authProvider={authProvider} loginPage={LoginPage} dashboard={Dashboard} {...props}>
    <Resource name="jobs" icon={Work} list={JobsList} show={JobShow} create={JobCreate} edit={JobEdit} />
    <Resource name="devices" icon={PhoneAndroid} list={DevicesList} show={DeviceShow} />
    <Resource name="apps" icon={AppsIcon} list={AppList} show={AppShow} />
    <Resource name="schemas" icon={DataObject} list={SchemaList} show={SchemaShow} />
    <Resource name="job_presets" icon={AutoStories} list={JobPresetList} show={JobPresetShow} edit={JobPresetEdit} create={JobPresetCreate} />
    <Resource name="job_templates" icon={Source} list={JobTemplateList} show={JobTemplateShow} />
    <Resource name="tasks" icon={DataObject} show={TaskShow} />
    <Resource name="users" icon={Person} list={UserList} show={UserShow} edit={UserEdit} recordRepresentation="email" />
    <Resource name="coupons" icon={LocalOffer} list={CouponList} show={CouponShow} create={CouponCreate} />
    <Resource name="access_codes" icon={KeyIcon} list={AccessCodeList} show={AccessCodeShow} edit={AccessCodeEdit} create={AccessCodeCreate} />
    <Resource name="cloud_runners" icon={Cloud} list={CloudRunnersList} show={CloudRunnerShow} />
    <CustomRoutes>
      <Route path="/callback" element={<Callback />} />
      <Route path="/profile" element={<Logout />} />
    </CustomRoutes>
  </Admin>
};

export default withAuth(App);
